/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { Link } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { object, string } from 'yup';
import { FaPhoneAlt } from '@react-icons/all-files/fa/FaPhoneAlt';
import { FaMapMarkerAlt } from '@react-icons/all-files/fa/FaMapMarkerAlt';

import Layout from '../components/Layout/Layout';

const schema = object().shape({
  fullName: string().required().label('Full Name'),
  // phoneNumber: string().required().label('Phone Number'),
  // email: string().email().required().label('Email'),
  message: string().label('Message'),
});

const ContactPage = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [checked, setChecked] = useState(false);

  const onSubmit = async (data) => {
    if (!checked) {
      setError('status', {
        type: 'manual',
        message: 'You must check the consent check box',
      });
      return;
    }

    try {
      const response = await fetch(
        'https://n06yu6md41.execute-api.us-west-1.amazonaws.com/default/sendContactEmail',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ ...data, formId: 29 }),
          referrerPolicy: 'no-referrer-when-downgrade',
        },
      );

      if (!response.ok) {
        throw new Error();
      }

      navigate('/thank-you/');
    } catch (err) {
      setError('status', {
        type: 'manual',
        message: 'Oops. Something went wrong. Please try again later.',
      });
    }
  };

  return (
    <Layout>
      <GatsbySeo
        title="Contact Us | Presidium Health - Home-based Healthcare"
        description="Presidium Health provides home-based care services to patients."
        metaTags={[
          {
            name: 'keywords', 
            content: 'home-based healthcare, value-based healthcare, patient care, telemedicine'
          }
        ]}
      />

      {/* HERO */}
      <div className="hero contact-hero d-flex align-items-lg-center p-relative pt-3 pt-lg-0 contact-hero">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="px-4 py-5 bg-primary text-white fs-3">
                <h3 className="mb-4">We&apos;d love to hear from you</h3>
              </div>
              <div className="bg-white shadow-lg py-2 px-4">
                <Form className="mt-n5 pb-4" onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <FloatingLabel
                          controlId="formFullName"
                          label="Full Name*"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Full Name"
                            {...register('fullName')}
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="flex-column flex-md-row">
                    <Col>
                      <Form.Group className="mb-3">
                        <FloatingLabel
                          controlId="formPhoneNumber"
                          label="Phone Number"
                          className="mb-3"
                        >
                          <Form.Control
                            type="tel"
                            placeholder="Phone Number"
                            {...register('phoneNumber')}
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <FloatingLabel
                          controlId="formEmail"
                          label="Email"
                          className="mb-3"
                        >
                          <Form.Control
                            type="email"
                            placeholder="Email"
                            isInvalid={!!errors.email}
                            {...register('email')}
                          />
                          <ErrorMessage
                            as={Form.Control.Feedback}
                            errors={errors}
                            name="email"
                            type="invalid"
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <FloatingLabel controlId="formMessage" label="Message">
                          <Form.Control
                            as="textarea"
                            placeholder="Message"
                            style={{ height: '120px' }}
                            isInvalid={!!errors.message}
                            {...register('message')}
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip
                            id="tooltip-top"
                            style={{
                              backgroundColor: 'black',
                              color: 'white',
                              borderRadius: '10px',
                              maxWidth: '550px',
                              padding: '5px 10px',
                            }}
                          >
                            By signing this form, you are agreeing to receive
                            recurring messages from Presidium Health Corp
                            related to your request for information. Message and
                            Data rates may apply. Carriers are not liable for
                            delayed or undelivered messages. You also confirm
                            that you have the authority to consent to receive
                            text messages at the number you provided. Text help
                            for help & stop to unsubscribe.
                          </Tooltip>
                        }
                      >
                        <Row>
                          <Form.Check
                            type="checkbox"
                            label=""
                            checked={checked}
                            onChange={(e) => setChecked(e.target.checked)}
                            style={{ width: 'auto' }}
                          />
                          <div style={{ flex: 1 }}>
                            I consent to receive information by text message
                            from the Brand Presidium Health Corp.{' '}
                            <u>
                              <Link
                                to="/privacy-policy/"
                              >
                                Terms & Conditions and Privacy Policy.
                              </Link>
                            </u>
                          </div>
                        </Row>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        className="mt-3"
                        variant="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? 'Submitting...' : 'Submit'}
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mt-3">
                        Not to be used for medical emergency. Do not include any
                        personal health information
                      </div>
                    </Col>
                  </Row>
                  {errors.status && (
                    <Alert variant="danger" className="mt-4">
                      {errors.status.message}
                    </Alert>
                  )}
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <section className="bg-gray py-5">
        <Container>
          {/* Boxes */}
          <Row className="align-items-center align-items-lg-stretch justify-content-center mt-4">
            <Col lg={5} className="mb-4 mb-lg-0">
              <div className="p-4 d-flex flex-column bg-white shadow-lg rounded-3 contact-box">
                <h3 className="text-uppercase text-primary">Patient Support</h3>
                <p className="mt-1 mb-3 fs-4">Contact our care team</p>
                <ul className="list-unstyled">
                  <li className="d-flex align-items-center py-1 fs-7 text-muted">
                    <FaPhoneAlt className="me-2" /> <span>619-738-5566</span>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={5} className="mb-4 mb-lg-0">
              <div className="p-4 d-flex flex-column text-white bg-primary shadow-primary rounded-3 contact-box">
                <h3 className="text-uppercase mb-3">BUSINESS OFFICE</h3>
                <br />
                <ul className="list-unstyled">
                  <li className="d-flex py-1 fs-7">
                    <FaMapMarkerAlt className="me-2" /> 3111 Camino Del Rio N
                    Suite 625
                    <br />
                    San Diego, CA 92108
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default ContactPage;
